/* eslint-disable @typescript-eslint/no-unused-vars */
import axios, {AxiosRequestConfig} from 'axios';
import {Glum} from 'puddleglum';
import {transformToQueryString, PaginatedResponse} from 'puddleglum/utils';

export default class RegisterController {
	static async lookupEmailAndSendVerification(
		request: Glum.Requests.Auth.UserLookupEmail = {} as Glum.Requests.Auth.UserLookupEmail,
		validationOnly: boolean = false,
		fieldToValidate: string = '',
		config: AxiosRequestConfig = {},
	) {
		return axios.post<{message: string}>(`/api/register/lookup-email`, request, {
			headers: {
				Precognition: validationOnly,
				...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
			},
			...config,
		});
	}

	static async verifyCode(
		request: Glum.Requests.Auth.UserVerifyEmail = {} as Glum.Requests.Auth.UserVerifyEmail,
		validationOnly: boolean = false,
		fieldToValidate: string = '',
		config: AxiosRequestConfig = {},
	) {
		return axios.post<{message: string; user: Glum.Models.User}>(
			`/api/register/verify-code`,
			request,
			{
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			},
		);
	}

	static async acceptTerms(
		request: {sms_consent: boolean},
		validationOnly: boolean = false,
		fieldToValidate: string = '',
		config: AxiosRequestConfig = {},
	) {
		return axios.post(`/api/register/accept-terms`, request, {
			headers: {
				Precognition: validationOnly,
				...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
			},
			...config,
		});
	}

	static async register(
		request: Glum.Requests.Auth.UserRegister = {} as Glum.Requests.Auth.UserRegister,
		validationOnly: boolean = false,
		fieldToValidate: string = '',
		config: AxiosRequestConfig = {},
	) {
		return axios.post<Glum.Models.User>(`/api/register`, request, {
			headers: {
				Precognition: validationOnly,
				...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
			},
			...config,
		});
	}
}
