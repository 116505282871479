import React, {useEffect, useCallback} from 'react';
import {
	Box,
	Button,
	Center,
	VStack,
	Text,
	chakra,
	Spinner,
	Progress,
	HStack,
	Image,
	Spacer,
} from '@chakra-ui/react';
import {Link, useNavigate} from 'react-router-dom';
import useMe from '../../../hooks/useMe';
import GradientHeading from '../components/GradientHeading';
import Page from '../components/Page';
import {Glum} from 'puddleglum';
import assetUrl from 'utils/assetUrl';

function Home() {
	const {me, isLoading} = useMe();
	const navigate = useNavigate();

	const routeToPortal = useCallback(
		(userRoles: Glum.Models.UserRole[]) => {
			if (userRoles?.length === 1) {
				if (userRoles?.find((role) => role.name === 'Admin')) {
					window.location.href = '/admin';
				} else if (userRoles?.find((role) => role.name === 'Provider')) {
					window.location.href = '/provider';
				} else if (userRoles?.find((role) => role.name === 'Employer')) {
					window.location.href = '/employer';
				} else if (userRoles?.find((role) => role.name === 'Patient')) {
					window.location.href = '/patient';
				}
			} else navigate('/select-user-type');
		},
		[navigate],
	);

	useEffect(() => {
		if (!me || !me?.roles) {
			return;
		}

		routeToPortal(me.roles);
	}, [me, routeToPortal]);

	if (isLoading) {
		return <Progress size='xs' isIndeterminate />;
	}

	return (
		<Page>
			<Center w='100%' h='100vh'>
				<VStack>
					<Box
						bgColor='white'
						p={3}
						rounded='3xl'
						boxShadow='md'
						maxW={{base: '100%', md: '800px'}}
					>
						<HStack w='full' justify='space-between' align='stretch'>
							<Box flex={2} display={{base: 'none', md: 'block'}}>
								<Image
									src='https://fleetofface.com/stock?key=wK-elt11pF0'
									alt='Allora Health'
									rounded='3xl'
									boxShadow='sm'
									maxW='320px'
									minH='420px'
									objectFit='cover'
								/>
							</Box>
							<Box flex={1} px={8} pb={8} minW='340px'>
								<VStack
									style={!isLoading && !me ? {} : {display: 'none'}}
									mt={4}
									w='full'
									h='full'
									align='flex-start'
								>
									<Center flex={1} w='full'>
										<Image
											src={assetUrl('/images/allora-logo.png')}
											alt='Allora'
											w='64px'
										/>
									</Center>
									<Box flex={1} w='full'>
										<Text fontSize='xs' color='gray.500'>
											Welcome to
										</Text>
										<GradientHeading>Allora Health</GradientHeading>
										<Center
											style={!isLoading ? {display: 'none'} : {}}
											w='100%'
											h='20vh'
										>
											<Spinner size='xl' />
										</Center>
										<Button
											as={Link}
											to='login'
											w='full'
											colorScheme='primary'
											mt={2}
										>
											Log In
										</Button>
										<Button
											as={Link}
											to='signup'
											w='full'
											variant='outline'
											mt={2}
										>
											Sign Up
										</Button>
									</Box>
									<Spacer />
									<Center fontSize='.8em' w='full'>
										<chakra.a href='/terms' target='_blank'>
											Terms of Service
										</chakra.a>
										<chakra.a
											ml={4}
											href='https://www.calvient.com/privacy-policy'
											target='_blank'
										>
											Privacy Policy
										</chakra.a>
									</Center>
								</VStack>
							</Box>
						</HStack>
					</Box>
				</VStack>
			</Center>
		</Page>
	);
}

export default Home;
