import React, {FC, useState} from 'react';
import {
	Alert,
	AlertIcon,
	AlertTitle,
	Box,
	Button,
	Center,
	FormControl,
	FormLabel,
	Input,
} from '@chakra-ui/react';
import {Link, useNavigate} from 'react-router-dom';
import GradientHeading from '../components/GradientHeading';
import Page from '../components/Page';
import ForgotPasswordController from 'puddleglum/api/auth/ForgotPasswordController';

const ForgotPassword: FC = () => {
	const [email, setEmail] = useState<string>();
	const [password, setPassword] = useState<string>();
	const [confirmPassword, setConfirmPassword] = useState<string>();
	const [confirmationCode, setConfirmationCode] = useState<string>();
	const [errorText, setErrorText] = useState<string>();
	const [isLoading, setIsLoading] = useState(false);
	const [readyForNewPassword, setReadyForNewPassword] = useState(false);
	const [successMessage, setSuccessMessage] = useState<string>();
	const navigate = useNavigate();

	const requestCode = async () => {
		try {
			setErrorText(undefined);
			setIsLoading(true);
			await ForgotPasswordController.forgotPassword({
				email: email ?? '',
			});
			setReadyForNewPassword(true);
		} catch ({response}) {
			// @ts-ignore to be fixed in puddleglum, giving response is unknown
			setErrorText(response.data.message);
			setReadyForNewPassword(false);
		} finally {
			setIsLoading(false);
		}
	};

	const resetPassword = async () => {
		if (password !== confirmPassword) {
			setErrorText('Passwords do not match');
			return;
		}

		try {
			setErrorText(undefined);
			setIsLoading(true);
			await ForgotPasswordController.resetPassword({
				email: email ?? '',
				confirmation_code: confirmationCode ?? '',
				password: password ?? '',
			});
			setReadyForNewPassword(false);
			setSuccessMessage('Password reset successfully');
			setTimeout(() => {
				navigate('/login');
			}, 1500);
		} catch ({response}) {
			// @ts-ignore to be fixed in puddleglum, giving response is unknown
			setErrorText(response.data.message);
			setIsLoading(false);
			setReadyForNewPassword(true);
		}
	};

	return (
		<Page>
			<Center w='100%' h='100vh'>
				<Box
					minW={{base: '90%', md: '50%', lg: '40%'}}
					bgColor='white'
					p={8}
					rounded='md'
					border='solid 1px'
					borderColor='gray.200'
					boxShadow='sm'
				>
					<form onSubmit={(e) => e.preventDefault()}>
						<GradientHeading>Forgot Password</GradientHeading>
						{errorText && (
							<Alert status='error'>
								<AlertIcon />
								<AlertTitle>{errorText}</AlertTitle>
							</Alert>
						)}

						{successMessage && (
							<Alert status='success'>
								<AlertTitle>{successMessage}</AlertTitle>
							</Alert>
						)}

						<FormControl id='email' my={2}>
							<FormLabel fontSize='.8em' color='gray.500'>
								Email
							</FormLabel>
							<Input
								type='email'
								placeholder='Email'
								value={email}
								onChange={(e) => setEmail(e.target.value)}
							/>
						</FormControl>

						{readyForNewPassword && (
							<>
								<FormControl id='confirmationCode' my={2}>
									<FormLabel fontSize='.8em' color='gray.500'>
										Confirmation Code
									</FormLabel>
									<Input
										type='text'
										placeholder='Confirmation Code'
										value={confirmationCode}
										onChange={(e) => setConfirmationCode(e.target.value)}
									/>
								</FormControl>
								<FormControl id='password' my={2}>
									<FormLabel fontSize='.8em' color='gray.500'>
										New Password
									</FormLabel>
									<Input
										type='password'
										placeholder='New Password'
										value={password}
										onChange={(e) => setPassword(e.target.value)}
									/>
								</FormControl>
								<FormControl>
									<FormLabel fontSize='.8em' color='gray.500'>
										Confirm New Password
									</FormLabel>
									<Input
										type='password'
										placeholder='Confirm New Password'
										value={confirmPassword}
										onChange={(e) => setConfirmPassword(e.target.value)}
									/>
								</FormControl>
							</>
						)}

						<Button
							type='submit'
							mt={4}
							colorScheme='blue'
							w='full'
							isLoading={isLoading}
							onClick={readyForNewPassword ? resetPassword : requestCode}
						>
							{readyForNewPassword ? 'Reset Password' : 'Request Code'}
						</Button>
						<Button as={Link} to='/' mt={2} variant='outline' w='full'>
							Back
						</Button>
					</form>
				</Box>
			</Center>
		</Page>
	);
};

export default ForgotPassword;
