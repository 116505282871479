import React from 'react';
import {Heading, HeadingProps} from '@chakra-ui/react';

interface GradientHeaderProps extends HeadingProps {
	children: React.ReactNode;
}

const GradientHeading = ({children, ...rest}: GradientHeaderProps) => (
	<Heading
		bgGradient='linear(to-l, primary.500, pink.700)'
		bgClip='text'
		fontSize='3xl'
		fontWeight='extrabold'
		{...rest}
	>
		{children}
	</Heading>
);

export default GradientHeading;
