import React, {FC, useEffect, useState} from 'react';
import {Box, Center, SimpleGrid} from '@chakra-ui/react';
import {
	MdAdminPanelSettings,
	MdBusiness,
	MdLocalHospital,
	MdMedication,
	MdPerson,
} from 'react-icons/md';
import GradientHeading from '../components/GradientHeading';
import IconBoxButton from '../components/IconBoxButton';
import Page from '../components/Page';
import UserController from 'puddleglum/api/UserController';

const RedirectPortal: FC = () => {
	const [showAdmin, setShowAdmin] = useState<boolean>();
	const [showPatient, setShowPatient] = useState<boolean>();
	const [showEmployer, setShowEmployer] = useState<boolean>();
	const [showProvider, setShowProvider] = useState<boolean>();
	const [showPharmacy, setShowPharmacy] = useState<boolean>();

	const getRoles = async () => {
		const response = await UserController.me();
		const userRoles = response.data.roles;

		userRoles?.map((role) => {
			if (role.name === 'Admin') {
				return setShowAdmin(true);
			}
			if (role.name === 'Patient') {
				return setShowPatient(true);
			}
			if (role.name === 'Employer') {
				return setShowEmployer(true);
			}
			if (role.name === 'Provider') {
				return setShowProvider(true);
			}
			if (role.name === 'Pharmacy') {
				return setShowPharmacy(true);
			}
			return null;
		});
	};

	useEffect(() => {
		getRoles();
	}, []);

	return (
		<Page>
			<Center w='100%' h='100vh'>
				<Box
					minW={{base: '90%', md: '50%', lg: '40%'}}
					bgColor='white'
					rounded='md'
					border='solid 1px'
					borderColor='gray.200'
					boxShadow='sm'
					p={8}
				>
					<GradientHeading>Allora Health</GradientHeading>
					<SimpleGrid columns={2} spacing={4} mt={4}>
						{showPatient && (
							<IconBoxButton
								w='full'
								onClick={() => window.location.replace('/patient')}
								icon={MdPerson}
								label={"I'm a patient"}
							/>
						)}
						{showEmployer && (
							<IconBoxButton
								w='full'
								onClick={() => window.location.replace('/employer')}
								icon={MdBusiness}
								label={"I'm an employer"}
							/>
						)}
						{showProvider && (
							<IconBoxButton
								w='full'
								onClick={() => window.location.replace('/provider')}
								icon={MdLocalHospital}
								label={"I'm a provider"}
							/>
						)}
						{showAdmin && (
							<IconBoxButton
								w='full'
								onClick={() => window.location.replace('/admin')}
								icon={MdAdminPanelSettings}
								label={"I'm an admin"}
							/>
						)}
						{showPharmacy && (
							<IconBoxButton
								w='full'
								onClick={() => window.location.replace('/pharmacy')}
								icon={MdMedication}
								label={"I'm a pharmacy"}
							/>
						)}
					</SimpleGrid>
				</Box>
			</Center>
		</Page>
	);
};

export default RedirectPortal;
