import React from 'react';
import {Alert, Box, BoxProps, Button, Text} from '@chakra-ui/react';
import useMe from '../../../hooks/useMe';
import AdminController from 'puddleglum/api/admin/AdminController';

interface PageProps extends BoxProps {
	children: React.ReactNode;
}

const Page = ({children, ...rest}: PageProps) => {
	const {me} = useMe();

	const handleImpersonationLogout = async () => {
		await AdminController.impersonationLogOut();
		window.location.reload();
	};

	return (
		<Box
			pos='fixed'
			top={0}
			right={0}
			left={0}
			bottom={0}
			bgImage="url('https://fleetofface.com/stock?key=BEeu6Oi5Wug')"
			bgPos='center'
			bgSize='cover'
			{...rest}
		>
			{me?.is_impersonating === true && (
				<Alert status='error' justifyContent='center'>
					<Text pr={4}>
						You are impersonating user {me.first_name} {me.last_name}.
					</Text>
					<Button onClick={handleImpersonationLogout} size='sm' colorScheme='red'>
						Click here to log out
					</Button>
				</Alert>
			)}
			<Box>{children}</Box>
		</Box>
	);
};

export default Page;
