import React, {FC, useState} from 'react';
import {
	Box,
	Button,
	Center,
	HStack,
	Spacer,
	Step,
	StepIcon,
	StepIndicator,
	Stepper,
	StepSeparator,
	StepStatus,
	Text,
	useSteps,
	useToast,
	VStack,
} from '@chakra-ui/react';
import Dependents from '../../../components/Dependents/Dependents';
import {Dependent} from '../../../components/Dependents/types';
import PuddleDate from '../../../components/fields/PuddleDate';
import PuddleInput from '../../../components/fields/PuddleInput';
import PuddleMaskedInput from '../../../components/fields/PuddleMaskedInput';
import PuddleSelect from '../../../components/fields/PuddleSelect';
import States from '../../../enums/States';
import useMe from '../../../hooks/useMe';
import usePuddleForm from '../../../hooks/usePuddleForm';
import GradientHeading from '../components/GradientHeading';
import Page from '../components/Page';
import PasswordHealth from '../components/PasswordHealth';
import {Glum} from 'puddleglum';
import RegisterController from 'puddleglum/api/auth/RegisterController';

const CompleteSignup: FC = () => {
	const {me} = useMe();
	const [showNavActions, setShowNavActions] = useState(true);
	const isDependentsEnabled = Boolean(me?.employer?.is_dependents_enabled) ?? false;
	const toast = useToast();

	const form = usePuddleForm<
		Glum.Models.User & {
			confirm_password: string;
			dependents: Dependent[];
		},
		Glum.Requests.Auth.UserRegister,
		Glum.Models.User
	>(
		{
			initialValues: {...me, dependents: []},
			storeFunction: RegisterController.register,
		},
		[me],
	);

	const handleSave = async () => {
		const isSaved = await form.save();
		if (isSaved) {
			toast({
				title: 'Success',
				description: 'Your information has been saved',
				status: 'success',
				duration: 9000,
				isClosable: true,
			});
			window.location.replace('/signup/accept-terms');
		} else {
			toast({
				title: 'Error',
				description: 'There was an error saving your information',
				status: 'error',
				duration: 9000,
				isClosable: true,
			});
		}
	};

	const doPasswordsMatch = form.model?.password === form.model?.confirm_password;

	const steps: Array<{
		name: string;
		children: React.ReactNode;
		isReady?: boolean;
		isHidden?: boolean;
	}> = [
		{
			name: 'Account Information',
			isReady:
				!!form.model?.first_name &&
				!!form.model?.last_name &&
				!!form.model?.personal_email &&
				!!form.model?.password &&
				!!form.model?.confirm_password &&
				doPasswordsMatch,
			children: (
				<VStack spacing={4} alignItems='stretch'>
					<PuddleInput isRequired label='First Name' fieldName='first_name' form={form} />
					<PuddleInput isRequired label='Last Name' fieldName='last_name' form={form} />
					<PuddleInput
						isRequired
						label='Personal Email'
						fieldName='personal_email'
						helperText='This should not be your work email. We ask for this to guarantee you can access Allora from home.'
						form={form}
					/>
					<PuddleInput
						isRequired
						label='Password'
						fieldName='password'
						form={form}
						type='password'
					/>
					<PuddleInput
						isRequired
						label='Confirm Password'
						fieldName='confirm_password'
						form={form}
						type='password'
					/>

					{!doPasswordsMatch && (
						<Text fontSize='.8em' color='red.500'>
							Passwords do not match
						</Text>
					)}

					<PasswordHealth password={form.model?.password ?? ''} />
				</VStack>
			),
		},
		{
			name: 'Personal Information',
			isReady: !!form.model?.date_of_birth && !!form.model?.sex && !!form.model?.cell_phone,
			children: (
				<VStack spacing={4}>
					<PuddleDate
						isRequired
						label='Date of Birth'
						startYear={new Date().getFullYear() - 100}
						endYear={new Date().getFullYear()}
						fieldName='date_of_birth'
						form={form}
					/>
					<PuddleSelect
						isRequired
						label='Sex'
						options={[
							{label: 'Female', value: 'F'},
							{label: 'Male', value: 'M'},
						]}
						fieldName='sex'
						form={form}
					/>
					<PuddleMaskedInput
						mask='(000) 000-0000'
						isRequired
						label='Cell Phone'
						fieldName='cell_phone'
						form={form}
						type='tel'
					/>
					<PuddleMaskedInput
						mask='(000) 000-0000'
						label='Home Phone'
						fieldName='home_phone'
						form={form}
						type='tel'
					/>
					<PuddleMaskedInput
						mask='(000) 000-0000'
						label='Work Phone'
						fieldName='work_phone'
						form={form}
						type='tel'
					/>
				</VStack>
			),
		},
		{
			name: 'Address Information',
			isReady:
				!!form.model?.street_address1 &&
				!!form.model?.city &&
				!!form.model?.state &&
				!!form.model?.zip,
			children: (
				<VStack spacing={4}>
					<PuddleInput
						isRequired
						label='Street Address 1'
						fieldName='street_address1'
						form={form}
					/>
					<PuddleInput label='Street Address 2' fieldName='street_address2' form={form} />
					<PuddleInput isRequired label='City' fieldName='city' form={form} />
					<PuddleSelect
						isRequired
						label='State'
						options={Object.entries(States).map(([state]) => ({
							label: state,
							value: state,
						}))}
						fieldName='state'
						form={form}
					/>
					<PuddleInput isRequired label='Zip' fieldName='zip' form={form} type='number' />
				</VStack>
			),
		},
		{
			name: 'Dependent Information',
			isReady: true,
			isHidden: !isDependentsEnabled || !form.model?.is_primary_benefit_holder,
			children: (
				<Dependents
					mt={4}
					initialValues={form.model?.dependents ?? []}
					onDependentFormOpen={() => setShowNavActions(false)}
					onDependentFormClose={() => setShowNavActions(true)}
					onChangeDependents={(dependents) => form.updateField('dependents', dependents)}
				/>
			),
		},
		{
			name: 'Review & Save',
			children: (
				<Box>
					<VStack w='full' spacing={4}>
						<HStack w='full'>
							<Text flex={1} color='gray.500' fontWeight='bold'>
								First Name:
							</Text>
							<Text flex={3}>{form.model?.first_name}</Text>
						</HStack>

						<HStack w='full'>
							<Text flex={1} color='gray.500' fontWeight='bold'>
								Last Name:
							</Text>
							<Text flex={3}>{form.model?.last_name}</Text>
						</HStack>

						<HStack w='full'>
							<Text flex={1} color='gray.500' fontWeight='bold'>
								Email:
							</Text>
							<Text flex={3}>{form.model?.email}</Text>
						</HStack>

						<HStack w='full'>
							<Text flex={1} color='gray.500' fontWeight='bold'>
								DOB:
							</Text>
							<Text flex={3}>{form.model?.date_of_birth}</Text>
						</HStack>

						<HStack w='full'>
							<Text flex={1} color='gray.500' fontWeight='bold'>
								Cell Phone:
							</Text>
							<Text flex={3}>{form.model?.cell_phone}</Text>
						</HStack>
						<HStack w='full'>
							<Text flex={1} color='gray.500' fontWeight='bold'>
								Home Phone:
							</Text>
							<Text flex={3}>{form.model?.home_phone || 'N/A'}</Text>
						</HStack>
						<HStack w='full' alignItems='flex-start'>
							<Text flex={1} color='gray.500' fontWeight='bold'>
								Address:
							</Text>
							<Box flex={3}>
								<Text>{form.model?.street_address1}</Text>
								<Text>{form.model?.street_address2}</Text>
								<Text>
									{form.model?.city}, {form.model?.state} {form.model?.zip}
								</Text>
							</Box>
						</HStack>
						<HStack w='full' alignItems='flex-start'>
							<Text flex={1} color='gray.500' fontWeight='bold'>
								Dependents:
							</Text>
							<Box flex={3}>
								<VStack spacing={2} alignItems='stretch'>
									{form.model?.dependents?.length === 0 && <Text>N/A</Text>}

									{form.model?.dependents?.map((d, i) => (
										// eslint-disable-next-line react/no-array-index-key -- okay here, because the data is static
										<Box key={i}>
											<HStack
												w='full'
												spacing={4}
												justifyContent='space-between'
											>
												<Box flex={3}>
													{d.first_name} {d.last_name}
												</Box>
											</HStack>
										</Box>
									))}
								</VStack>
							</Box>
						</HStack>
					</VStack>
				</Box>
			),
		},
	];

	const visibleSteps = steps.filter((step) => !step.isHidden);

	const {activeStep, goToNext, goToPrevious} = useSteps({
		index: 0,
		count: visibleSteps.length,
	});

	return (
		<Page overflow='auto'>
			<Center w='100%' p={{base: 'none', sm: 8}}>
				<Box
					pos={{base: 'fixed', sm: 'relative'}}
					top={0}
					bottom={0}
					overflowY='auto'
					w={{base: '100%', sm: '60%', lg: '50%'}}
					bgGradient='linear(to-b, white, gray.200)'
					rounded={{base: 'none', sm: 'xl'}}
					boxShadow='md'
					p={8}
				>
					<Stepper size='sm' index={activeStep} gap='0'>
						{visibleSteps.map((step) => (
							// @ts-expect-error - Chakra UI types are wrong
							<Step key={step.name} gap={0}>
								<StepIndicator>
									<StepStatus complete={<StepIcon />} />
								</StepIndicator>
								<StepSeparator
									// @ts-expect-error - Chakra UI type are wrong
									_horizontal={{ml: '0'}}
								/>
							</Step>
						))}
					</Stepper>

					<GradientHeading my={4}>{visibleSteps[activeStep].name}</GradientHeading>

					{visibleSteps[activeStep].children}

					{showNavActions && (
						<HStack justifyContent='space-between' py={4}>
							{activeStep > 0 && (
								<Button variant='outline' colorScheme='blue' onClick={goToPrevious}>
									Back
								</Button>
							)}

							<Spacer />

							{activeStep < visibleSteps.length - 1 && (
								<Button
									colorScheme='blue'
									onClick={goToNext}
									isDisabled={!visibleSteps[activeStep].isReady}
								>
									Next
								</Button>
							)}

							{activeStep === visibleSteps.length - 1 && (
								<Button
									colorScheme='blue'
									onClick={handleSave}
									isLoading={form.isSaving}
								>
									Save
								</Button>
							)}
						</HStack>
					)}
				</Box>
			</Center>
		</Page>
	);
};
export default CompleteSignup;
